* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

table {
    border-collapse: collapse;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
    background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
    border-color: #7abaff;
}

.table-hover .table-primary:hover {
    background-color: #9fcdff;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #9fcdff;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
    background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
    border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
    background-color: #c8cbcf;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #c8cbcf;
}

.table-success,
.table-success>th,
.table-success>td {
    background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
    border-color: #8fd19e;
}

.table-hover .table-success:hover {
    background-color: #b1dfbb;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b1dfbb;
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
    border-color: #86cfda;
}

.table-hover .table-info:hover {
    background-color: #abdde5;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5;
}

.table-warning,
.table-warning>th,
.table-warning>td {
    background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
    border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffe8a1;
}

.table-danger,
.table-danger>th,
.table-danger>td {
    background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
    border-color: #ed969e;
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7;
}

.table-light,
.table-light>th,
.table-light>td {
    background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
    border-color: #fbfcfc;
}

.table-hover .table-light:hover {
    background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6;
}

.table-dark,
.table-dark>th,
.table-dark>td {
    background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
    border-color: #95999c;
}

.table-hover .table-dark:hover {
    background-color: #b9bbbe;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b9bbbe;
}

.table-active,
.table-active>th,
.table-active>td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.table-dark {
    color: #fff;
    background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #454d55;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm>.table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
    border: 0;
}

.table {
    border-collapse: collapse !important;
}

.table td,
.table th {
    background-color: #fff !important;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
}

.table-dark {
    color: inherit;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
    border-color: #dee2e6;
}

.table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
}

.react-bootstrap-table table {
    table-layout: fixed;
}

.react-bootstrap-table th.sortable {
    cursor: pointer;
}

.react-bootstrap-table th .order>.dropdown>.caret {
    margin: 10px 0 10px 5px;
    color: #cccccc;
}

.react-bootstrap-table th .order>.dropup>.caret {
    margin: 10px 0;
    color: #cccccc;
}

.react-bootstrap-table th>.react-bootstrap-table-sort-order>.caret {
    margin: 10px 6.5px;
}

.react-bootstrap-table th .order-4:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
}

.react-bootstrap-table th .order-4:after {
    content: "\2193";
    opacity: 0.4;
}

.react-bootstrap-table th .caret-4-asc:before {
    margin-left: 3.5px;
    content: "\2191";
}

.react-bootstrap-table th .caret-4-asc:after {
    content: "\2193";
    opacity: 0.4;
}

.react-bootstrap-table th .caret-4-desc:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
}

.react-bootstrap-table th .caret-4-desc:after {
    content: "\2193";
}

.react-bootstrap-table th[data-row-selection] {
    width: 30px;
}

.react-bootstrap-table th>.selection-input-4,
.react-bootstrap-table td>.selection-input-4 {
    margin: -4px;
}

.react-bootstrap-table td.react-bs-table-no-data {
    text-align: center;
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated {
    animation-fill-mode: both;
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.bounceIn,
.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.bounceOut {
    animation-duration: .75s;
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.shake {
    animation-duration: .3s;
}

@keyframes shake {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }
    10%,
    50%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }
    30%,
    70% {
        transform: translate3d(10px, 0, 0);
    }
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .shake {
    animation-name: shake;
}

@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .bounceIn {
    animation-name: bounceIn;
}

@keyframes bounceOut {
    20% {
        transform: scale3d(0.9, 0.9, 0.9);
    }
    50%,
    55% {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .bounceOut {
    animation-name: bounceOut;
}

.react-bootstrap-table .reset-expansion-style {
    padding: 0;
}

.react-bootstrap-table .row-expansion-style {
    padding: 8px;
}

.react-bootstrap-table .row-expand-slide-appear {
    max-height: 0;
    overflow: hidden;
}

.react-bootstrap-table .row-expand-slide-appear-active {
    max-height: 1000px;
    transition: max-height 3s linear;
}

.react-bootstrap-table .row-expand-slide-exit {
    max-height: 1000px;
}

.react-bootstrap-table .row-expand-slide-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: max-height 400ms cubic-bezier(0, 0.95, 0, 0.95);
}

[data-baseweb="popover"] {
    z-index: 99;
}

.gantt .bar-wrapper.active .bar {
    fill: #666d92 !important;
}

.gantt .bar-wrapper.active .bar-progress {
    fill: rgb(76, 175, 80) !important;
}

.timeline.timeline-6 {
    position: relative;
}

.timeline.timeline-6:before {
    content: '';
    position: absolute;
    left: 151px;
    width: 4px;
    top: 0;
    bottom: 0;
    background-color: #EBEDF3;
}

.timeline.timeline-6 .timeline-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin-top: 1.7rem;
}

.timeline.timeline-6 .timeline-item:last-child {
    margin-bottom: 0;
}

.timeline.timeline-6 .timeline-item:first-child {
    margin-top: 0;
}

.timeline.timeline-6 .timeline-item .timeline-label {
    width: 150px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 1rem;
    font-weight: 500;
    position: relative;
    color: #3F4254;
}

.timeline.timeline-6 .timeline-item .timeline-badge {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background: white;
    width: 13px;
    height: 13px;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /*z-index: 1;*/
    position: relative;
    margin-top: 1px;
    margin-left: -0.5rem;
    padding: 3px !important;
    border: 6px solid #ffffff !important;
}

.timeline.timeline-6 .timeline-item .timeline-badge span {
    display: block;
    border-radius: 100%;
    font-weight: 500;
    font-size: 1.4rem;
}

.timeline.timeline-6 .timeline-item .timeline-content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.text-primary {
    color: #3699FF !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #0073e9 !important;
}

.text-secondary {
    color: #E4E6EF !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #b4bad3 !important;
}

.text-success {
    color: #1BC5BD !important;
}

a.text-success:hover,
a.text-success:focus {
    color: #12827c !important;
}

.text-info {
    color: #8950FC !important;
}

a.text-info:hover,
a.text-info:focus {
    color: #5605fb !important;
}

.text-warning {
    color: #FFA800 !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #b37600 !important;
}

.text-danger {
    color: #F64E60 !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #ec0c24 !important;
}* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

table {
    border-collapse: collapse;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
    background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
    border-color: #7abaff;
}

.table-hover .table-primary:hover {
    background-color: #9fcdff;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #9fcdff;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
    background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
    border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
    background-color: #c8cbcf;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #c8cbcf;
}

.table-success,
.table-success>th,
.table-success>td {
    background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
    border-color: #8fd19e;
}

.table-hover .table-success:hover {
    background-color: #b1dfbb;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b1dfbb;
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
    border-color: #86cfda;
}

.table-hover .table-info:hover {
    background-color: #abdde5;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5;
}

.table-warning,
.table-warning>th,
.table-warning>td {
    background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
    border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffe8a1;
}

.table-danger,
.table-danger>th,
.table-danger>td {
    background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
    border-color: #ed969e;
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7;
}

.table-light,
.table-light>th,
.table-light>td {
    background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
    border-color: #fbfcfc;
}

.table-hover .table-light:hover {
    background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6;
}

.table-dark,
.table-dark>th,
.table-dark>td {
    background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
    border-color: #95999c;
}

.table-hover .table-dark:hover {
    background-color: #b9bbbe;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b9bbbe;
}

.table-active,
.table-active>th,
.table-active>td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.table-dark {
    color: #fff;
    background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #454d55;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm>.table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
    border: 0;
}

.table {
    border-collapse: collapse !important;
}

.table td,
.table th {
    background-color: #fff !important;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
}

.table-dark {
    color: inherit;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
    border-color: #dee2e6;
}

.table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
}

.react-bootstrap-table table {
    table-layout: fixed;
}

.react-bootstrap-table th.sortable {
    cursor: pointer;
}

.react-bootstrap-table th .order>.dropdown>.caret {
    margin: 10px 0 10px 5px;
    color: #cccccc;
}

.react-bootstrap-table th .order>.dropup>.caret {
    margin: 10px 0;
    color: #cccccc;
}

.react-bootstrap-table th>.react-bootstrap-table-sort-order>.caret {
    margin: 10px 6.5px;
}

.react-bootstrap-table th .order-4:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
}

.react-bootstrap-table th .order-4:after {
    content: "\2193";
    opacity: 0.4;
}

.react-bootstrap-table th .caret-4-asc:before {
    margin-left: 3.5px;
    content: "\2191";
}

.react-bootstrap-table th .caret-4-asc:after {
    content: "\2193";
    opacity: 0.4;
}

.react-bootstrap-table th .caret-4-desc:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
}

.react-bootstrap-table th .caret-4-desc:after {
    content: "\2193";
}

.react-bootstrap-table th[data-row-selection] {
    width: 30px;
}

.react-bootstrap-table th>.selection-input-4,
.react-bootstrap-table td>.selection-input-4 {
    margin: -4px;
}

.react-bootstrap-table td.react-bs-table-no-data {
    text-align: center;
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated {
    animation-fill-mode: both;
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.bounceIn,
.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.bounceOut {
    animation-duration: .75s;
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.shake {
    animation-duration: .3s;
}

@keyframes shake {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }
    10%,
    50%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }
    30%,
    70% {
        transform: translate3d(10px, 0, 0);
    }
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .shake {
    animation-name: shake;
}

@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .bounceIn {
    animation-name: bounceIn;
}

@keyframes bounceOut {
    20% {
        transform: scale3d(0.9, 0.9, 0.9);
    }
    50%,
    55% {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .bounceOut {
    animation-name: bounceOut;
}

.react-bootstrap-table .reset-expansion-style {
    padding: 0;
}

.react-bootstrap-table .row-expansion-style {
    padding: 8px;
}

.react-bootstrap-table .row-expand-slide-appear {
    max-height: 0;
    overflow: hidden;
}

.react-bootstrap-table .row-expand-slide-appear-active {
    max-height: 1000px;
    transition: max-height 3s linear;
}

.react-bootstrap-table .row-expand-slide-exit {
    max-height: 1000px;
}

.react-bootstrap-table .row-expand-slide-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: max-height 400ms cubic-bezier(0, 0.95, 0, 0.95);
}

[data-baseweb="popover"] {
    z-index: 99;
}

.gantt .bar-wrapper.active .bar {
    fill: #666d92 !important;
}

.gantt .bar-wrapper.active .bar-progress {
    fill: rgb(76, 175, 80) !important;
}

.timeline.timeline-6 {
    position: relative;
}

.timeline.timeline-6:before {
    content: '';
    position: absolute;
    left: 151px;
    width: 4px;
    top: 0;
    bottom: 0;
    background-color: #EBEDF3;
}

.timeline.timeline-6 .timeline-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin-top: 1.7rem;
}

.timeline.timeline-6 .timeline-item:last-child {
    margin-bottom: 0;
}

.timeline.timeline-6 .timeline-item:first-child {
    margin-top: 0;
}

.timeline.timeline-6 .timeline-item .timeline-label {
    width: 150px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 1rem;
    font-weight: 500;
    position: relative;
    color: #3F4254;
}

.timeline.timeline-6 .timeline-item .timeline-badge {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background: white;
    width: 13px;
    height: 13px;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /*z-index: 1;*/
    position: relative;
    margin-top: 1px;
    margin-left: -0.5rem;
    padding: 3px !important;
    border: 6px solid #ffffff !important;
}

.timeline.timeline-6 .timeline-item .timeline-badge span {
    display: block;
    border-radius: 100%;
    font-weight: 500;
    font-size: 1.4rem;
}

.timeline.timeline-6 .timeline-item .timeline-content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.text-primary {
    color: #3699FF !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #0073e9 !important;
}

.text-secondary {
    color: #E4E6EF !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #b4bad3 !important;
}

.text-success {
    color: #1BC5BD !important;
}

a.text-success:hover,
a.text-success:focus {
    color: #12827c !important;
}

.text-info {
    color: #8950FC !important;
}

a.text-info:hover,
a.text-info:focus {
    color: #5605fb !important;
}

.text-warning {
    color: #FFA800 !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #b37600 !important;
}

.text-danger {
    color: #F64E60 !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #ec0c24 !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;